<template lang="pug">
	.d-flex.flex-column.h-100.main
		router-view
</template>

<script>
export default {
    beforeMount() {
        document.body.classList.add('background-main');
    },
    destroyed() {
        document.body.classList.remove('background-main');
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.main {
    max-width: 1440px;
    margin: 0 auto;
    padding: 28px 109px 0;

    @include respond-below(md) {
        padding: 12px 12px 0;
    }
}
</style>
